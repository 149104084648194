import React, { useState } from 'react';
import './title-highlight.scss';

export interface IProps {
  title: string;
}
export const TitleHighlight = (props: IProps) => {
  return (
    <>
      <div className={'tag-page__title-wrap'}>
        <h1 className={'tag-page__title'}>{props.title}</h1>
      </div>
    </>
  );
};
