import React, { useLayoutEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { PostListLayout } from 'app/shared/layout/post/post-list/post-list-layout';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TitleHighlight } from 'app/shared/layout/title-highlight/title-highlight';
import { PostHotStoryLayout } from 'app/shared/layout/post/post-hot-story/post-hostory-layout';
import { getAnalysisPosts } from 'app/shared/reducers/post.reducer';
import { ANALYSIS_NEWS } from 'app/config/constants';

export const PostListAnalysis = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const data: Array<any> = useAppSelector(state => state?.postReducer.analysisPost);
  const listHotStory: Array<any> = useAppSelector(state => state?.postReducer.featuredPosts);

  useLayoutEffect(() => {
    dispatch(getAnalysisPosts);
  });
  return (
    <>
      <Spin spinning={loading}>
        <div className={'container'}>
          <TitleHighlight title={'Analysis News'} />
          <Row gutter={[16, 16]}>
            <Col md={14}>
              <PostListLayout posts={data} category={ANALYSIS_NEWS} />
            </Col>
            <Col md={2} className={'sticky-item'}></Col>
            <Col md={8} className={'sticky-item'}>
              <PostHotStoryLayout posts={listHotStory} />
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};
