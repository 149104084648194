// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-page__title-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.tag-page__title {
  position: relative;
  margin: 0 0 24px;
  padding-bottom: 12px;
  font-size: 1.875rem;
  line-height: 1.1;
  color: #010101;
  font-weight: 600;
  margin-right: 16px;
}

.tag-page__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15%;
  height: 2px;
  max-width: 50px;
  min-width: 30px;
  background-color: #ffcd04;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/title-highlight/title-highlight.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,UAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,yBAAA;AAEF","sourcesContent":[".tag-page__title-wrap {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: left;\n}\n\n.tag-page__title {\n  position: relative;\n  margin: 0 0 24px;\n  padding-bottom: 12px;\n  font-size: 1.875rem;\n  line-height: 1.1;\n  color: #010101;\n  font-weight: 600;\n  margin-right: 16px;\n}\n.tag-page__title::after {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 15%;\n  height: 2px;\n  max-width: 50px;\n  min-width: 30px;\n  background-color: #ffcd04;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
