import React, { useEffect, useState } from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { PostCardHotStory } from 'app/shared/layout/post/post-hot-story/post-card-hot-story';

export interface IProps {
  posts: IPrimeBanner[];
}

export const PostHotStoryLayout = (props: IProps) => {
  return (
    <>
      <div style={{ position: 'relative', color: 'var(--text-gray-color)' }}>
        <h4>Hot Stories</h4>
        <ul>{props?.posts.map((item, index) => <PostCardHotStory post={item} key={item.slug + index} />)}</ul>
      </div>
    </>
  );
};
