import dayjs from 'dayjs';

import { APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATE_FORMAT) : null);

export const convertDateTimeToServer = (date?: string): dayjs.Dayjs | null => (date ? dayjs(date) : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);
export const convertDatePost = (dateString: string) => {
  const currentDate = new Date();
  const date = new Date(dateString);

  const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
  const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
  const diffHours = Math.floor(timeDiff / (1000 * 3600));

  if (diffDays < 1) {
    if (diffHours > 0) {
      return `${diffHours} giờ${diffHours > 1 ? '' : ''} trước`;
    } else {
      return 'Ngay bây giờ';
    }
  } else {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month < 10 ? '0' + month : month}-${year}`;
  }
};
