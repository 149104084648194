import axios from 'axios';
import { IPaginationRequest } from 'app/shared/model/pagination.model';

const rootApi = 'api/search';

export const searchPostByKeyWord = async (keyword: string, page) => {
  const payload: IPaginationRequest = { keyword, page };
  return await axios.post(`${rootApi}`, payload);
};

export const searchRecommend = async (keyword: string) => {
  return await axios.get(`${rootApi}/recommend?keyword=${keyword}`);
};
