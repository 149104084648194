import React from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { useAppSelector } from 'app/config/store';
import { Slider3Item } from 'app/shared/layout/slider/slider-3-item';
import { SliderPrediction } from 'app/shared/layout/slider/slider-prediction';
import { Col, Row } from 'antd';

export interface IProps {
  post: IPrimeBanner[];
}

export const PredictionPrice = (props: IProps) => {
  return (
    <>
      <Row gutter={[16, 8]} className={'background-highlight uni-block_card'}>
        <Col span={24} className={'press-release__container press-release__container__header'} style={{ marginBottom: 8, marginTop: 16 }}>
          <p className={'title-bold'}>Dự đoán thị trường</p>
        </Col>
        <Col span={24} style={{ padding: 0 }}>
          <SliderPrediction posts={props?.post || []} height={'350px'} />
        </Col>
      </Row>
    </>
  );
};
