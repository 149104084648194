import React from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { Tabs } from 'antd';
import { AndroidOutlined, AppleOutlined, EyeOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'app/config/store';
import { handleBannerHover } from 'app/shared/reducers/post.reducer';
import { useNavigate } from 'react-router-dom';

export interface IProps {
  posts: IPrimeBanner[];
  tabNumber: any;
}

export const BannerTabLayout = (props: IProps) => {
  return (
    <>
      <div className={''}>
        <Tabs
          defaultActiveKey={props.tabNumber}
          items={[AppleOutlined, AndroidOutlined].map((Icon, i) => {
            const id = String(i + 1);
            return {
              key: id,
              label: id === '1' ? 'Tin tức mới nhất' : 'Tin tức nổi bật',
              children: <TabContent posts={props?.posts} />,
              icon: <Icon />,
            };
          })}
        />
      </div>
    </>
  );
};
export const TabContent = (props: { posts: IPrimeBanner[] }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleBanner = post => {
    dispatch(handleBannerHover(post));
  };
  return (
    <>
      <div className={'container'}>
        <ul>
          {props?.posts.map(item => (
            <li key={item.slug} className={'title-focus'} onMouseOver={() => handleBanner(item)} onClick={() => navigate(`/${item.slug}`)}>
              <div className={'title-forcus__item'}>
                <a className={`text-hover-bold ${item?.isCur ? 'active-text' : ''}`}>{item.title}</a>
                <div className={'d-flex'} style={{ justifyContent: 'flex-end' }}>
                  <div style={{ marginRight: 4 }}>
                    <EyeOutlined />
                  </div>
                  <div>
                    <span>{item?.view}</span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
