// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-post-item {
  min-height: 392px;
  width: 100%;
  padding: 0px;
  border-radius: 8px;
  height: 100%;
  transition: background-color 0.5s ease;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/body/market-news/market-news-layout.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,sCAAA;AAAF","sourcesContent":[".recent-post-item {\r\n  min-height: 392px;\r\n  //min-width: 370px;\r\n  width: 100%;\r\n  padding: 0px;\r\n  border-radius: 8px;\r\n  height: 100%;\r\n  transition: background-color 0.5s ease;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
