// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { PostPrevewItem } from 'app/shared/layout/post/post-preview/post-prevew-item';
import { IPrimeBanner } from 'app/shared/model/post.model';

import '../post/post-preview/post-preview.scss';
export const Slider3Item = (props: { posts: IPrimeBanner[]; height?: string; fontSize?: number }) => {
  return (
    <div style={{ minHeight: 158 }} className={'press-release__container'}>
      {/* <div className={'press-release__container press-release__container__header'}> */}
      {/*   <p className={'title-bold'}>Tin Tức Mới nhất</p> */}
      {/* </div> */}
      <div className={'press-release__container press-release__container__body'}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: false }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
        >
          {props?.posts?.map(item => (
            <SwiperSlide key={item.slug}>
              <PostPrevewItem post={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
