export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

// --------------------------------------Post---------------------------------------------
export const POST_TYPE_NEWS = 1;
export const POST_TYPE_FEATURE = 2;

export const LASTEST_NEWS = 3;
export const MARKET_NEWS = 1;
export const ANALYSIS_NEWS = 2;
export const SEARCH_NEWS = 0;
export const TYPE_LOADING = [
  {
    prop: 'balls',
    name: 'Balls',
  },
  {
    prop: 'bars',
    name: 'Bars',
  },
  {
    prop: 'bubbles',
    name: 'Bubbles',
  },
  {
    prop: 'cubes',
    name: 'Cubes',
  },
  {
    prop: 'cylon',
    name: 'Cylon',
  },
  {
    prop: 'spin',
    name: 'Spin',
  },
  {
    prop: 'spinningBubbles',
    name: 'SpinningBubbles',
  },
  {
    prop: 'spokes',
    name: 'Spokes',
  },
];

export const WEBSOCKET_TOPIC = {
  RECENT_POST: 'notification.news.recent',
};
