import React from 'react';
import { Layout, Menu, Input } from 'antd';
import { Brand } from 'app/shared/layout/header/header-components';
import { SearchAnimation1 } from 'app/shared/layout/search/search-animation-1';
import { ROUTER } from 'app/router';
import { Link } from 'react-router-dom';

const { Header } = Layout;
const { Search } = Input;

const items = [
  {
    key: '1',
    label: <Link to={ROUTER.POST_LIST_MARKET}>Tin tức</Link>,
  },
  {
    key: '2',
    label: <Link to={ROUTER.POST_LIST_ANALYSIS}>Phân tích thị trường</Link>,
  },
  {
    key: '3',
    label: <Link to={ROUTER.POST_LIST_MARKET}>Kiến thức</Link>,
  },
];

export const HeaderSearch = () => {
  return (
    <>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: 40 }} className={'col-md-1'}>
          <Brand />
        </div>
        <div className={'col-md-6'}>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            items={items}
            style={{ flex: 1, minWidth: 0, color: 'white', fontWeight: 600, fontSize: '16px' }}
          />
        </div>
        <div className={'col-md-1'}></div>
        <div className={'col-md-4 d-flex justify-content-end'}>
          <SearchAnimation1 />
        </div>
      </Header>
    </>
  );
};
