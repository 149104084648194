import { IAuthor } from 'app/shared/model/user.model';

export interface IPrimeBanner {
  id?: number;
  title?: string;
  slug?: string;
  image?: string;
  category?: string;
  author?: IAuthor;
  publishDate?: any;
  view?: any;
  isCur?: boolean;
  type?: number;
  summary?: string;
  catId?: any;
}

export interface IPostDetail extends IPrimeBanner {
  content?: string;
  referenceUrl?: string;
  referenceTitle?: string;
  tags?: string[];
}
export interface IPostsPayload {
  catId: number;
  page: number;
}
export const mapToIPrimeBanner = (data: any, isFirst): IPrimeBanner => {
  return {
    id: data.id || null,
    title: data.title || '',
    slug: data.slug || '',
    image: data.thumbnail || '', // Assuming thumbnail corresponds to image
    author: {
      fullName: data.author,
    }, // Assuming author is of type string
    publishDate: data.createdDate || null, // Assuming createdDate is in ISO 8601 format
    view: data.view || 0, // Assuming view is of type number
    isCur: isFirst,
    catId: data.catId || null,
    summary: data.summary || null,
  };
};
