import React, { useEffect } from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
export interface IProps {
  post: IPrimeBanner;
}
import './banner.scss';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
export const PrimeBanner = (props: IProps) => {
  return (
    <>
      <div className={'prime-post__container cursor'} style={{ background: `url(${props?.post?.image})` }}>
        <header className={'absolute left-0 top-0 block w-full text-fg-inverted  bg-custom-gray-dark'} style={{ padding: '4px 16px' }}>
          <a href={props?.post?.title}>
            <span className={'white-text__focus post-title text-hover-bold'}>{props?.post?.title}</span>
          </a>
        </header>
        <footer
          className={
            'prime-banner__footer absolute left-0 bottom-0 flex justify-between items-center w-full text-fg-inverted bg-custom-gray-dark p-2'
          }
        >
          <div className={'d-flex title-footer__text'} style={{ padding: '4px 16px' }}>
            <span className={'white-text__focus active-text'}>{props?.post?.category}</span>
            <span className={'white-text__focus'}>{props?.post?.author?.fullName}</span>
            <span className={'white-text__focus'}>{convertDateTimeFromServer(props?.post?.publishDate.toString())}</span>
          </div>
        </footer>
      </div>
    </>
  );
};
