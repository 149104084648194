export const ROUTER = {
  //ANONYMOUS
  LOGIN: 'login',
  LOGOUT: 'logout',
  ACCOUNT: 'account',
  REGISTER: 'register',
  ACTIVATE: 'activate',
  POST_DETAIL: 'tin-tuc',
  POST_LIST_MARKET: 'thi-truong',
  POST_LIST_ANALYSIS: 'phan-tich-thi-truong',
  SEARCH: 'search',
};
// export default ROUTER;
