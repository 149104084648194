// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --active-color: #fb0;
  --primary-color: #3498db;
  --border-color: rgb(199 210 215);
  --text-gray-color: #a1a1a1;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/css/global.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EAEA,wBAAA;EACA,gCAAA;EACA,0BAAA;AAAF","sourcesContent":[":root {\n  --active-color: #fb0;\n  //--primary-color:#3498db;\n  --primary-color: #3498db;\n  --border-color: rgb(199 210 215);\n  --text-gray-color: #a1a1a1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
