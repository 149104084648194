import React, { useLayoutEffect, useRef, useState } from 'react';
import { IPrimeBanner, mapToIPrimeBanner } from 'app/shared/model/post.model';
import { PostCardList } from 'app/shared/layout/post/post-list/post-card-list';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAnalysisNews, getTopMarketNews } from 'app/api/post.api';
import { ANALYSIS_NEWS, MARKET_NEWS, SEARCH_NEWS } from 'app/config/constants';
import { searchPostByKeyWord } from 'app/api/search.api';

export interface IProps {
  posts: IPrimeBanner[];
  category: any;
  keyword?: any;
}

export const PostListLayout = (props: IProps) => {
  const [listPost, setListPost] = useState<IPrimeBanner[]>([]);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const page = useRef(0);
  // listening data
  useLayoutEffect(() => {
    //refresh data
    setListPost(props.posts);
    setHasMoreItems(true);
  }, [props.posts]);

  const fetchMoreData = () => {
    switch (props.category) {
      case MARKET_NEWS:
        fetchMarketNews();
        break;
      case ANALYSIS_NEWS:
        fetchAnalysisNews();
        break;
      case SEARCH_NEWS:
        fetchSearch();
        break;
      default:
        fetchMarketNews();
    }
    // fetch load more
  };
  const fetchMarketNews = () => {
    getTopMarketNews(page.current + 1).then(resp => {
      page.current = page.current + 1;
      if (resp.data && resp.data.length > 0) {
        //have data--> append new data
        const newData = resp.data.map(item => mapToIPrimeBanner(item, false));
        setTimeout(() => {
          setListPost([...listPost, ...newData]);
        }, 500);
      } else {
        //loaded all item
        setHasMoreItems(false);
      }
    });
  };
  const fetchAnalysisNews = () => {
    getAnalysisNews(page.current + 1).then(resp => {
      page.current = page.current + 1;
      if (resp.data && resp.data.length > 0) {
        //have data--> append new data
        const newData = resp.data.map(item => mapToIPrimeBanner(item, false));
        setTimeout(() => {
          setListPost([...listPost, ...newData]);
        }, 500);
      } else {
        //loaded all item
        setHasMoreItems(false);
      }
    });
  };
  const fetchSearch = () => {
    searchPostByKeyWord(props?.keyword, page.current + 1).then(resp => {
      page.current = page.current + 1;
      if (resp.data && resp.data.length > 0) {
        //have data--> append new data
        const newData = resp.data.map(item => mapToIPrimeBanner(item, false));
        setTimeout(() => {
          setListPost([...listPost, ...newData]);
        }, 500);
      } else {
        //loaded all item
        setHasMoreItems(false);
      }
    });
  };
  return (
    <>
      <InfiniteScroll next={fetchMoreData} hasMore={hasMoreItems} loader={<h4>Loading...</h4>} dataLength={listPost.length}>
        <ul>
          {listPost.map((item, index) => (
            <PostCardList post={item} key={item.slug + index} />
          ))}
        </ul>
      </InfiniteScroll>
    </>
  );
};
