import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapToIPrimeBanner } from 'app/shared/model/post.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { getPostRelated, getTopAnalysisNews, getTopFeaturedNews, getTopLastestNews, getTopMarketNews } from 'app/api/post.api';

export interface IState {
  latestPosts: Array<any>;
  marketPost: Array<any>;
  analysisPost: Array<any>;
  relatedPosts: Array<any>;
  featuredPosts: Array<any>;
}
const initialState: IState = {
  latestPosts: [],
  marketPost: [],
  analysisPost: [],
  relatedPosts: [],
  featuredPosts: [],
};

export const getLastestPosts = createAsyncThunk(
  'post/getLastestPosts',
  async () => {
    const response = await getTopLastestNews();
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getRelatedPosts = createAsyncThunk(
  'post/getRelatedPosts',
  async (id: number) => {
    const response = await getPostRelated(id);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getMarketPosts = createAsyncThunk(
  'post/getMarketPosts',
  async () => {
    const response = await getTopMarketNews();
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getAnalysisPosts = createAsyncThunk(
  'post/getAnalysisPosts',
  async () => {
    const response = await getTopAnalysisNews();
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getFeaturedPosts = createAsyncThunk(
  'post/getFeaturedPosts',
  async () => {
    const response = await getTopFeaturedNews();
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
    handleBannerHover(state, action) {
      const post = action.payload;
      state.latestPosts = state.latestPosts.map(p => ({
        ...p,
        isCur: p.slug === post?.slug,
      }));
    },
    addLatestPost(state?, action?: PayloadAction<any>) {
      const newPosts = action.payload.map((item, index) => mapToIPrimeBanner(item, index === 0));
      // Set isCur: false for all current posts
      const updatedPosts = state.latestPosts.map(post => ({
        ...post,
        isCur: false,
      }));
      // Merge new posts with current posts
      const mergedPosts = [...newPosts, ...updatedPosts];
      // Take top 5 posts
      state.latestPosts = mergedPosts.slice(0, 5);
    },
  },
  extraReducers: builder => {
    builder
      // Get latest posts
      .addCase(getLastestPosts.fulfilled, (state: IState, action) => {
        const data = action.payload;
        if (data && Array.isArray(data)) {
          state.latestPosts = data.map((item, index) => mapToIPrimeBanner(item, index === 0));
        }
      })
      // Get related posts
      .addCase(getRelatedPosts.fulfilled, (state: IState, action) => {
        const data = action.payload;
        if (data && Array.isArray(data)) {
          state.relatedPosts = data.map((item, index) => mapToIPrimeBanner(item, index === 0));
        }
      })
      // Get market posts
      .addCase(getMarketPosts.fulfilled, (state: IState, action) => {
        const data = action.payload;
        if (data && Array.isArray(data)) {
          state.marketPost = data.map((item, index) => mapToIPrimeBanner(item, index === 0));
        }
      })
      // Get analytics
      .addCase(getAnalysisPosts.fulfilled, (state: IState, action) => {
        const data = action.payload;
        if (data && Array.isArray(data)) {
          state.analysisPost = data.map((item, index) => mapToIPrimeBanner(item, index === 0));
        }
      })
      // Get Featured
      .addCase(getFeaturedPosts.fulfilled, (state: IState, action) => {
        const data = action.payload;
        if (data && Array.isArray(data)) {
          state.featuredPosts = data.map((item, index) => mapToIPrimeBanner(item, index === 0));
        }
      });
  },
});

export const { reset, handleBannerHover, addLatestPost } = postSlice.actions;
const postReducer = postSlice.reducer;
export default postReducer;
