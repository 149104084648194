import axios from 'axios';
import { IPostsPayload } from 'app/shared/model/post.model';
import { ANALYSIS_NEWS, LASTEST_NEWS, MARKET_NEWS } from 'app/config/constants';

const rootApi = 'api/post';

export const getPostDetail = async (slug: string) => {
  return await axios.get(`${rootApi}/getDetailPost?url=${slug}&lang=en`);
};

export const getPostRelated = async (id: number) => {
  const payload = { id };
  return await axios.post(`${rootApi}/related`, payload);
};

export const getTopLastestNews = async () => {
  const model: IPostsPayload = {
    catId: LASTEST_NEWS,
    page: 0,
  };
  return await axios.post(`${rootApi}/posts`, model);
};

export const getTopMarketNews = async (page?) => {
  const model: IPostsPayload = {
    catId: MARKET_NEWS,
    page: page || 0,
  };
  return await axios.post(`${rootApi}/posts`, model);
};
export const getTopAnalysisNews = async () => {
  const model: IPostsPayload = {
    catId: ANALYSIS_NEWS,
    page: 0,
  };
  return await axios.post(`${rootApi}/posts`, model);
};
export const getAnalysisNews = async (page?) => {
  const model: IPostsPayload = {
    catId: MARKET_NEWS,
    page: page || 0,
  };
  return await axios.post(`${rootApi}/posts`, model);
};

export const getTopFeaturedNews = async () => {
  return await axios.get(`${rootApi}/featured`);
};
