// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.press-release__container__body {
  border: 1px solid #dfdfdf;
  padding: 16px 8px;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/post/post-preview/post-preview.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".press-release__container__body {\r\n  border: 1px solid #dfdfdf;\r\n  padding: 16px 8px;\r\n  border-radius: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
