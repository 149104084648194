import React from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { Tooltip } from 'antd';

export interface IProps {
  post: IPrimeBanner;
}
export const PostCardHotStory = (props: IProps) => {
  return (
    <>
      <li style={{ height: 240, width: 240 }}>
        <div className={'post-card-item__hot__story'}>
          <div className={'images-container-item images-container-item__hot__story'}>
            <img src={props?.post?.image} alt={props?.post?.title} className={'cursor'} style={{ minHeight: 0 }} />
          </div>
          <div className={'post-card-item__hot__story title-post-container__hot_story'}>
            <header className={'title-post-item'}>
              <div className={'d-flex align-content-center'}>
                <a className={'title-default cursor text-hover-bold'} href={props?.post?.slug}>
                  {props?.post?.title}
                </a>
              </div>
            </header>
          </div>
        </div>
      </li>
    </>
  );
};
