import React, { useEffect, useState } from 'react';
import { IPostDetail, IPrimeBanner } from 'app/shared/model/post.model';
import { Col, Row } from 'antd';
import { PostDetailCard } from 'app/modules/post/post-detail/post-detail-card';
import { PostHotStoryLayout } from 'app/shared/layout/post/post-hot-story/post-hostory-layout';

export interface IProps {
  post: IPostDetail;
  postRelated: IPrimeBanner[];
}
export const PostDetailLayout = (prop: IProps) => {
  return (
    <>
      <div className={'container'}>
        <Row gutter={[16, 16]}>
          <Col md={19}>
            <PostDetailCard post={prop?.post} postRelated={prop?.postRelated} />
          </Col>
          <Col md={1} className={'sticky-item'}></Col>
          <Col md={4} className={'sticky-item'}>
            <PostHotStoryLayout posts={prop?.postRelated} />
          </Col>
        </Row>
      </div>
    </>
  );
};
