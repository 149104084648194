// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #353535;
  padding: 12px;
  border-radius: 50%;
  float: right;
  color: #ff5722;
  transition: 0.4s ease;
}

.search-box:hover > .search-input {
  width: 240px;
  margin: 0px 8px;
}

.search-input:focus {
  width: 240px;
  margin: 0px 8px;
}

.search-box:hover > .search-btn {
  background: #fff;
}

.search-input:focus + .search-btn {
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/search/search.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;AACF;;AACA;EACE,YAAA;EACA,eAAA;AAEF;;AAAA;EACE,YAAA;EACA,eAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AAFA;EACE,gBAAA;AAKF","sourcesContent":[".search-btn {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  text-decoration: none;\r\n  background: #353535;\r\n  padding: 12px;\r\n  border-radius: 50%;\r\n  float: right;\r\n  color: #ff5722;\r\n  transition: 0.4s ease;\r\n}\r\n.search-box:hover > .search-input {\r\n  width: 240px;\r\n  margin: 0px 8px;\r\n}\r\n.search-input:focus {\r\n  width: 240px;\r\n  margin: 0px 8px;\r\n}\r\n.search-box:hover > .search-btn {\r\n  background: #fff;\r\n}\r\n.search-input:focus + .search-btn {\r\n  background: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
