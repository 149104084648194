import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { PrimeBanner } from 'app/shared/layout/banner/prime-banner';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { BannerTabLayout } from 'app/shared/layout/banner/banner-tab-layout';
import { useAppSelector } from 'app/config/store';
import postReducer from 'app/shared/reducers/post.reducer';
import { Slider3Item } from 'app/shared/layout/slider/slider-3-item';

export const Banner = () => {
  const data = useAppSelector(state => state.postReducer.latestPosts);
  const [curPost, setCurPost] = useState<IPrimeBanner>(null);
  useEffect(() => {
    const p = data.find(item => item.isCur);
    setCurPost(p);
  }, [data]);
  return (
    <>
      <Row style={{ marginBottom: 36, padding: 20 }} className={'background-highlight'}>
        <Col md={24} style={{ height: '80%', marginBottom: 32 }} className={'d-flex'}>
          <Col md={16}>
            <PrimeBanner post={curPost} />
          </Col>
          <Col md={8} style={{ marginLeft: 24 }}>
            <BannerTabLayout posts={data} tabNumber={String(curPost?.type) || '1'} />
          </Col>
        </Col>
        <Col md={24}>
          <div className={'press-release__container press-release__container__header'} style={{ marginBottom: 16 }}>
            <p className={'title-bold'}>Tin Tức Mới nhất</p>
          </div>
          <Slider3Item posts={data} />
        </Col>
      </Row>
    </>
  );
};
