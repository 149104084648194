import React, { useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import './search.scss';
import { searchRecommend } from 'app/api/search.api';
import { useNavigate } from 'react-router-dom';
import { ROUTER } from 'app/router';

export const SearchAnimation1 = () => {
  const navigate = useNavigate();
  const search = keyword => {
    navigate(`${ROUTER.SEARCH}?keyword=${keyword}`);
  };

  const [options, setOptions] = useState<any>([]);

  const handleSearch = async (value: string) => {
    const r = await searchResult(value);
    setOptions(value ? r : []);
  };

  const onSelect = (value: string) => {
    search(value); //search
  };

  const searchResult = async value => {
    try {
      const resp = await searchRecommend(value);
      return resp.data.map(item => {
        return {
          value: item.keyword,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: 600 }}>{item.keyword}</p>
              <span style={{ color: 'var(--gray-color)', fontSize: 9 }}>{item.view} lượt tìm kiếm</span>
            </div>
          ),
        };
      });
    } catch (reason) {
      return [];
    }
  };
  return (
    <>
      <div className={'d-flex align-content-center'}>
        <AutoComplete
          popupMatchSelectWidth={330}
          style={{ width: 400 }}
          options={options}
          onSelect={onSelect}
          onSearch={handleSearch}
          size="large"
        >
          <Input.Search
            allowClear
            style={{ width: '90%', height: '100%' }}
            size={'large'}
            defaultValue=""
            placeholder={'Nhập từ khóa '}
            onSearch={value => search(value)}
          />
        </AutoComplete>
      </div>
    </>
  );
};
