import React, { useEffect, useState } from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import './post-card-item.scss';
import { convertDatePost } from 'app/shared/util/date-utils';
import { Tooltip } from 'antd';

export interface IProps {
  post: IPrimeBanner;
  isBorder?: boolean;
}

export const PostCardItem = (props: IProps) => {
  return (
    <>
      <div className={'post-card-item'}>
        <div className={'images-container-item'}>
          <Tooltip
            title={props?.post?.title}
            mouseEnterDelay={0.2}
            placement={'bottomLeft'}
            overlayInnerStyle={{
              position: 'absolute',
              bottom: '60px',
              left: '20px',
              width: '300px',
              height: '180px',
              backgroundColor: 'rgba(19,17,17,0.6)',
              padding: '20px',
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            <img src={props?.post?.image} alt={props?.post?.title} className={'cursor'} />
          </Tooltip>
        </div>
        <div className={'post-card-item title-post-container'}>
          <header className={'title-post-item'}>
            <div className={'d-flex align-content-center'}>
              <a className={'title-default cursor text-hover-bold'} href={props?.post?.slug}>
                {props?.post?.title}
              </a>
            </div>
          </header>
          <footer className={'d-flex'}>
            <div className={'d-flex col-6'}>
              <p className={'title-bold'} style={{ fontSize: 14, marginRight: 4 }}>
                Tác giả:
              </p>
              <p className={'title-bold text-hover-bold'} style={{ fontSize: 14 }}>
                {props?.post?.author?.fullName}
              </p>
            </div>
            <p className={'title-bold col-6'} style={{ fontSize: 14, textAlign: 'end' }}>
              {convertDatePost(props?.post?.publishDate)}
            </p>
          </footer>
        </div>
      </div>
    </>
  );
};
