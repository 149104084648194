import React from 'react';
import { MarketNewsLayout } from 'app/shared/layout/body/market-news/market-news-layout';
import { useAppSelector } from 'app/config/store';
import { PredictionPrice } from 'app/shared/layout/body/price-news/prediction-price';

export const BodyLayout = () => {
  const marketData = useAppSelector(state => state.postReducer.marketPost);
  const analysisData = useAppSelector(state => state.postReducer.analysisPost);

  return (
    <>
      <div>
        <MarketNewsLayout posts={marketData} />
        <PredictionPrice post={analysisData} />
      </div>
    </>
  );
};
