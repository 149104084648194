import React from 'react';
import { IPostDetail, IPrimeBanner } from 'app/shared/model/post.model';
import { Col, Row } from 'antd';
import { convertDatePost } from 'app/shared/util/date-utils';
import './post-detail-card.scss';
import { TagList } from 'app/shared/layout/tags/tag-list';
import { Slider3Item } from 'app/shared/layout/slider/slider-3-item';

export interface IProps {
  post: IPostDetail;
  postRelated: IPrimeBanner[];
}

export const PostDetailCard = (props: IProps) => {
  return (
    <>
      {props.post && (
        <>
          {/* Title nav  */}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h1 className={'title-bold post-title-bold '}>{props?.post?.title}</h1>
            </Col>
          </Row>
          {/* Author nav */}
          <Row gutter={[16, 16]}>
            <Col span={24} className={'d-flex author-reference-nav'}>
              <div className={'col-8 d-flex'}>
                <div className={'d-flex justify-center mr-4'}>
                  <img className={'avatar-image-item'} alt={props?.post?.author?.fullName} src={props?.post?.author?.avatar} />
                </div>
                <div className={'d-flex align-items-center'}>
                  <p className={'title-gray text-hover-bold'} style={{ fontSize: '18px' }}>
                    {props?.post?.author?.fullName}
                  </p>
                </div>
              </div>
              <div className={'col-4 d-flex justify-content-end'}>
                <p className={'title-gray'}>{convertDatePost(props?.post?.publishDate)}</p>
              </div>
            </Col>
          </Row>

          {/* Content nav  */}
          <Row gutter={[16, 16]} className={'post-content-container'}>
            <Col span={24}>
              <div dangerouslySetInnerHTML={{ __html: props?.post?.content }}></div>
            </Col>
          </Row>
          {/* reference */}
          <Row gutter={[16, 16]} className={'post-content-container'} style={{ marginBottom: 16 }}>
            <p style={{ fontWeight: 600, fontStyle: 'italic' }}>
              Tham khảo tại :
              <a className={'text-decoration__active'} href={props?.post?.referenceUrl}>
                {props?.post?.referenceTitle}
              </a>
            </p>
          </Row>
          {/* Tag */}
          <Row gutter={[16, 16]} className={'post-content-container'} style={{ marginBottom: 16 }}>
            <TagList tags={props?.post?.tags} />
          </Row>
          {props?.postRelated && (
            <Row gutter={[16, 16]}>
              <Col md={24}>
                <div className={'press-release__container press-release__container__header'} style={{ marginBottom: 16 }}>
                  <p className={'title-bold'}>Bài viết liên quan</p>
                </div>
                <Slider3Item posts={props?.postRelated || []} />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
