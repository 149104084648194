import React from 'react';
import { Banner } from 'app/shared/layout/banner/banner';
import { BodyLayout } from 'app/shared/layout/body/body-layout';
export const HomeClient = () => {
  return (
    <>
      <Banner />
      <BodyLayout />
    </>
  );
};
