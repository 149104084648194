// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-card-item {
  display: flex;
  flex-direction: column;
}

.images-container-item {
  height: 60%;
  min-height: 280px;
}

.images-container-item img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.25rem;
  margin-right: 10px;
  width: 100%;
  height: 100%;
  min-height: 280px;
}

.title-post-container {
  padding: 0px;
}

.title-post-container footer,
header {
  padding: 12px 20px;
}

.images-container-item__hot__story {
  min-height: 100px !important;
}

.post-card-item__hot__story {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.title-post-container__hot_story header {
  padding: 12px 0px;
}

.title-post-container__hot_story {
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/post/post-preview/post-card-item.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;AACF;;AACA;EACE,oBAAA;KAAA,iBAAA;EACA,sBAAA;EACA,kBAAA;EAEA,WAAA;EACA,YAAA;EACA,iBAAA;AACF;;AAGA;EACE,YAAA;AAAF;;AAEA;;EAEE,kBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EAEA,YAAA;EACA,WAAA;AACF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".post-card-item {\r\n  display: flex;\r\n  flex-direction: column;\r\n  //padding: 16px;\r\n}\r\n.images-container-item {\r\n  height: 60%;\r\n  min-height: 280px;\r\n}\r\n.images-container-item img {\r\n  object-fit: cover;\r\n  border-radius: 0.25rem;\r\n  margin-right: 10px;\r\n  //width: 376px;\r\n  width: 100%;\r\n  height: 100%;\r\n  min-height: 280px;\r\n}\r\n.title-post-container header {\r\n}\r\n.title-post-container {\r\n  padding: 0px;\r\n}\r\n.title-post-container footer,\r\nheader {\r\n  padding: 12px 20px;\r\n}\r\n\r\n.images-container-item__hot__story {\r\n  min-height: 100px !important;\r\n}\r\n.post-card-item__hot__story {\r\n  display: flex;\r\n  flex-direction: column;\r\n  //padding: 16px;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n.title-post-container__hot_story header {\r\n  padding: 12px 0px;\r\n}\r\n.title-post-container__hot_story {\r\n  padding: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
