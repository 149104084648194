import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { Storage } from 'react-jhipster';
import { addLatestPost } from 'app/shared/reducers/post.reducer';

const WebSocketComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loc = window.location;
    const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
    let url = loc.protocol + '//' + loc.host + baseHref + '/websocket/tracker';
    const authToken = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if (authToken) {
      url += '?access_token=' + authToken;
    }

    const socket = new SockJS(url);
    const stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
      stompClient.subscribe('/topic/notification.news.recent', message => {
        const newPosts = JSON.parse(message.body);
        dispatch(addLatestPost(newPosts));
      });
    });

    return () => {
      stompClient.disconnect();
    };
  }, [dispatch]);

  return <div>WebSocket Connected</div>;
};

export default WebSocketComponent;
