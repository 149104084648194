import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Tag } from 'antd';
export const TagList = ({ tags }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Space size={[0, 8]} wrap>
          {tags?.map((item, index) => (
            <Tag key={item + index}>
              <a href="https://github.com/ant-design/ant-design/issues/1862" style={{ fontSize: 16, fontWeight: 500 }}>
                #{item}
              </a>
            </Tag>
          ))}
        </Space>
      </Col>
    </Row>
  );
};
