// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prime-post__container {
  height: 512px;
  width: 100%;
  position: relative;
  background-size: contain !important;
}

.prime-banner__footer {
  width: 100%;
  position: absolute;
  min-height: 56px;
}

.post-title {
  font-size: 33px;
}

.title-footer__text span {
  margin-right: 16px;
  font-size: 16px;
  font-weight: 600;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.prime-post__container header {
  min-height: 56px;
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/banner/banner.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,WAAA;EACA,kBAAA;EACA,mCAAA;AAAF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,eAAA;AAEF;;AAAA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAGF;;AADA;EACE,gBAAA;EACA,wBAAA;EAAA,mBAAA;AAIF","sourcesContent":[".prime-post__container {\r\n  height: 512px;\r\n  //width: 747px;\r\n  width: 100%;\r\n  position: relative;\r\n  background-size: contain !important;\r\n}\r\n.prime-banner__footer {\r\n  width: 100%;\r\n  position: absolute;\r\n  min-height: 56px;\r\n}\r\n.post-title {\r\n  font-size: 33px;\r\n}\r\n.title-footer__text span {\r\n  margin-right: 16px;\r\n  font-size: 16px;\r\n  font-weight: 600;\r\n  min-height: 40px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.prime-post__container header {\r\n  min-height: 56px;\r\n  height: fit-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
