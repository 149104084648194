import React, { useLayoutEffect, useState } from 'react';
import { IPostDetail } from 'app/shared/model/post.model';
import { PostDetailLayout } from 'app/shared/layout/post/post-detail/post-detail-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { getPostDetail } from 'app/api/post.api';
import { Spin } from 'antd';
import { getRelatedPosts } from 'app/shared/reducers/post.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PostDetail = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams<'slug'>();
  const [data, setData] = useState<IPostDetail>();
  const [loading, setLoading] = useState(true);
  const postRelated = useAppSelector(state => state.postReducer.relatedPosts);
  const navigate = useNavigate();
  // Get detail  post
  useLayoutEffect(() => {
    if (slug) {
      getPostDetail(slug)
        .then(resp => {
          setData(resp.data);
          setLoading(false);
        })
        .catch(reason => {
          console.error(reason);
          navigate('/');
        });
    } else {
      navigate('/');
    }
  }, [slug]);

  // Get related post when have detail
  useLayoutEffect(() => {
    if (data) {
      dispatch(getRelatedPosts(data?.id));
    }
  }, [data]);

  return (
    <>
      <Spin spinning={loading}>
        <PostDetailLayout post={data} postRelated={postRelated} />
      </Spin>
    </>
  );
};
