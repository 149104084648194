// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.author-reference-nav div {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/post/post-detail/post-detail-card.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".author-reference-nav div {\r\n  margin-right: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
