import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import './market-news-layout.scss';
import { PostCardItem } from 'app/shared/layout/post/post-preview/post-card-item';
import { IPrimeBanner } from 'app/shared/model/post.model';

export const MarketNewsLayout = (props: { posts: IPrimeBanner[] }) => {
  return (
    <>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col span={24} className={'press-release__container press-release__container__header'} style={{ marginBottom: 8, marginTop: 16 }}>
          <p className={'title-bold'}>Tin tức thị trường </p>
        </Col>
        {props?.posts?.map((post, index) => (
          <Col lg={8} md={12} key={post?.slug + index}>
            <div className={'recent-post-item background-highlight'}>
              <PostCardItem post={post} />
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};
