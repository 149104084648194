import React from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { Col, Row } from 'antd';
import { convertDatePost } from 'app/shared/util/date-utils';
import './post-list-card.module.scss';
import { EyeOutlined } from '@ant-design/icons';
import { ANALYSIS_NEWS, MARKET_NEWS } from 'app/config/constants';

export interface IProps {
  post: IPrimeBanner;
}

export const PostCardList = (props: IProps) => {
  const getCategoryLabel = catId => {
    switch (catId) {
      case MARKET_NEWS:
        return 'MARKETS NEWS';
      case ANALYSIS_NEWS:
        return 'ANALYSIS NEWS';
      default:
        return 'MARKETS NEWS';
    }
  };
  return (
    <>
      <li style={{ minHeight: 150, marginTop: 14 }}>
        <Row className={'d-flex container'} gutter={[16, 16]} style={{ height: '100%' }}>
          <Col span={10} style={{ height: '100%' }}>
            <div className={'images-container-item'} style={{ minHeight: 138, width: '98%' }}>
              {/*<LazyLoad height={138} offset={100} placeholder={<div className="skeleton" />}>*/}
              <img src={props?.post?.image} alt={props?.post?.title} className={'cursor'} style={{ minHeight: 0 }} />
              {/*</LazyLoad>*/}
              <span className={'post-card-inline__badge post-card-inline__badge_default'}>{getCategoryLabel(props?.post.catId)}</span>
            </div>
          </Col>
          <Col span={14}>
            <div style={{ marginBottom: 6 }}>
              <a className={'title-default cursor text-hover-bold'} href={props?.post?.slug}>
                {props?.post?.title}
              </a>
            </div>
            <div className={'d-flex'} style={{ marginBottom: 6 }}>
              <span style={{ marginRight: 4 }} className={'title-gray'}>
                {convertDatePost(props?.post?.publishDate)}
              </span>
              <span style={{ marginRight: 6 }} className={'title-gray'}>
                |
              </span>
              <span style={{ marginRight: 6 }} className={'title-gray'}>
                Tác giả {props?.post?.author?.fullName}
              </span>
            </div>
            <div className={'mb-6'} style={{ marginBottom: 6 }}>
              <p className={'max-line3'}>{props?.post?.summary}</p>
            </div>
            <div className={'d-flex flex-start'}>
              <div style={{ marginRight: 4 }}>
                <EyeOutlined />
              </div>
              <div>
                <span>{props?.post?.view}</span>
              </div>
            </div>
          </Col>
        </Row>
      </li>
    </>
  );
};
