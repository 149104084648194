import React, { useEffect, useState } from 'react';
import { IPrimeBanner } from 'app/shared/model/post.model';
import { useNavigate } from 'react-router-dom';

export interface IProps {
  post: IPrimeBanner;
}
export const PostPrevewItem = (props: IProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={'container'}>
        <div className={'d-flex post-preview-container'}>
          <div className={'image-small-container'}>
            <img src={props?.post?.image} alt={props?.post?.title} />
          </div>
          <div className={'d-flex align-content-center'}>
            <a className={'title-default cursor text-hover-bold'} onClick={() => navigate(`/${props?.post.slug}`)}>
              {props?.post?.title}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
