import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { PostListLayout } from 'app/shared/layout/post/post-list/post-list-layout';
import { useAppSelector } from 'app/config/store';
import { TitleHighlight } from 'app/shared/layout/title-highlight/title-highlight';
import { PostHotStoryLayout } from 'app/shared/layout/post/post-hot-story/post-hostory-layout';
import { SEARCH_NEWS } from 'app/config/constants';
import { searchPostByKeyWord } from 'app/api/search.api';
import { IPrimeBanner, mapToIPrimeBanner } from 'app/shared/model/post.model';
import { useLocation } from 'react-router-dom';

export const PostListSearch = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPrimeBanner[]>([]);
  const listHotStory: Array<any> = useAppSelector(state => state?.postReducer.featuredPosts);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('keyword') || '';

  useEffect(() => {
    search(keyword);
    // if (keyword) {
    // }
  }, [keyword]);

  const search = keyword => {
    setLoading(true);
    searchPostByKeyWord(keyword, 0)
      .then(resp => {
        setData(resp.data.map(item => mapToIPrimeBanner(item, false)));
      })
      .catch(reason => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="container">
        <TitleHighlight title="Search result" />
        <Row gutter={[16, 16]}>
          <Col md={14}>
            <PostListLayout posts={data} category={SEARCH_NEWS} keyword={keyword} />
          </Col>
          <Col md={2} className="sticky-item"></Col>
          <Col md={8} className="sticky-item">
            <PostHotStoryLayout posts={listHotStory} />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
